import React, { useEffect, useState } from 'react';
import { graphql }                    from 'gatsby';
import useScript                      from 'react-script-hook';

import SEO       from '@interness/web-core/src/components/modules/SEO/SEO';
import BrandsSubnavigation
                 from '@interness/brands-addon/src/components/modules/BrandsSubnavigation/BrandsSubnavigation';
import { findT } from '@interness/web-core/src/components/_helpers';

const TagHeuerFrame = ({ tagHeuerId }) => {
  const [frameInitialized, setFrameInitialized] = useState(false);
  const [loading] = useScript({
    src: tagHeuerId ? 'https://epartner.tagheuer.com/injectors/ecorner-injector.js' : null,
    checkForExisting: true
  });

  useEffect(() => {
    if (!loading) {
      setFrameInitialized(true);
    }
    // const contentWindow = !!document.getElementById('TAG_HEUER_ECORNER').contentWindow;
    if (frameInitialized && tagHeuerId && window) {
      window.TAGHeuer.ECorner('#TAG_HEUER_ECORNER', {
        retailerId: tagHeuerId,
        lang: 'de',
      })
    }
  }, [loading, frameInitialized, tagHeuerId]);

  return (
    <div>
      <div id="TAG_HEUER_ECORNER"/>
    </div>
  )
}

const TagHeuerBrandPageTemplate = props => {
  const [hasMounted, setHasMounted] = useState(false);
  const data = props.data.directusBrands.brand;
  const translations = findT(data.translations, props.pageContext.lang);

  const tagHeuerId = props.data.directusProject.custom_values && props.data.directusProject.custom_values.tag_vendor_id;

  useEffect(() => {
    setHasMounted(true);
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <SEO title={data.display_name}
           description={translations.seo_description ? translations.seo_description : null}
           lang={translations.language}/>
      <BrandsSubnavigation type={props.data.directusBrandTypes.type}/>
      {hasMounted && <TagHeuerFrame tagHeuerId={tagHeuerId}/>}
    </>
  );
};


export default TagHeuerBrandPageTemplate;

export const query = graphql`
    query($slug: String!, $type: String!) {
        directusBrands(brand: {slug: {eq: $slug}, type: {type: {eq: $type}}}) {
            ...BrandsCore
        }
        directusBrandTypes(type: {eq: $type}) {
            type
            translations {
                display_name
                slug
                language
            }
        }
        directusProject(id: {ne: "dummy"}) {
            custom_values {
                tag_vendor_id
            }
        }
    }
`;